define(['jquery', 'siteObj'], function($, siteObj) {
  
  /*
   * An object that represents the data associated with the options available on a Product Details page.
   * @constructor
   */
  function ProductDetails(config) {

    if (!config) {
      throw new Error('Configuration object is required');
    }

    if (!config.details) {
      throw new Error('product [details] are required in the configuration object');
    }

    /*
     * Private vars
     */
    var details = config.details;
    var selections = config.selections || (Object.create ? Object.create(null) : {}); // IE7
    var quantity = config.quantity || 1;
    var handlers = {};

    /*
     * Private functions
     */
    function events(event) {
      return handlers[event] || (handlers[event] = $.Callbacks());
    }

    function selectVariation(variation, option) {

      if (!variation || isNaN(variation)) return;
      if (option && isNaN(option)) return;

      variation = parseInt(variation, 10);
      option = parseInt(option, 10);

      if (!details.variations[variation]) return;
      if (option && !details.variations[variation].options[option]) return;

      // store or remove the selected option for the variation
      if (option) {
        selections[variation] = option;
      } else {
        delete selections[variation];
      }
    }

    function filterChildProducts(productSelections) {
      var matches = [];
      for (var childProductId in details.children) {
        var childProduct = details.children[childProductId];
        if (productMatchesSelections(childProduct, productSelections)) {
          matches.push(childProduct);
        }
      }
      return matches;
    }

    function productMatchesSelections(product, productSelections) {
      productSelections = productSelections || selections;

      var rtn = true;
      for (var variationId in productSelections) {
        if (product.options[variationId] !== productSelections[variationId]) {
          rtn = false;
          break;
        }
      }
      return rtn;
    }

    function optionIsValid(variationId, optionId, filteredProducts) {
      var rtn = false;
      for (var i = 0; i < filteredProducts.length; i++) {
        if (filteredProducts[i].options[variationId] === optionId) {
          rtn = true;
          break;
        }
      }
      return rtn;
    }

    /*
     * Privileged 'properties' (ES5 defineProperties when IE7/8 dropped)
     */
    this.details = function() {
      return details;
    };
    this.selections = function() {
      return selections;
    };
    this.variations = function() {
      return details.variations;
    };
    this.selectedChildProductId = function() {
      // determine the selected child product
      var selectedChildProductId;
      for (var childProductId in details.children) {
        var childProduct = details.children[childProductId];
        var matched = true;
        for (var variationId in childProduct.options) {
          if (selections[variationId] !== childProduct.options[variationId]) {
            matched = false;
            break;
          }
        }

        if (matched) {
          selectedChildProductId = childProductId;
          break;
        }
      }
      return selectedChildProductId;
    };
    this.selectedChildProduct = function() {
      var selectedChildProductId = this.selectedChildProductId();
      return selectedChildProductId
        ? details.children[selectedChildProductId]
        : null;
    };
    this.price = function() {
      // return the selected child products' price OR the default price IF there's no selected child product
      var child = this.selectedChildProduct();
      return child
        ? child.price
        : details.product.price;
    };
    this.priceRRP = function() {
      // return the selected child products' RRP price
      var child = this.selectedChildProduct();
      return child && child.rrpSP
        ? siteObj.props.ajaxBasket.titles.basketRRP + '&nbsp;<span class="strike">' + child.rrpD + '</span>'
        : '';
    };
    this.savingAmount = function() {
      // return the selected child products' rrpSavingAmount
      var child = this.selectedChildProduct();
      return child && child.rrpSP
        ? siteObj.props.ajaxBasket.titles.basketSave + '&nbsp;' + child.rrpS
        : '';
    };
    this.savingPercent = function() {
      // return the selected child products' rrpSavingPercent
      var child = this.selectedChildProduct();
      return child && child.rrpSP
        ? siteObj.props.ajaxBasket.titles.basketSave + '&nbsp;<span>' + child.rrpSP + '</span>'
        : '';
    };
    this.quantity = function(value) {
      if (typeof value !== 'number') {
        return quantity;
      }
      quantity = Math.max(1, value);
      events(this.events.QUANTITY_CHANGED).fireWith(this, [quantity]);
    };
    this.canAddToBasket = function() {
      // return true if we have a quantity and all variants have a valid selection
      return quantity && this.selectedChildProductId();
    };
    this.isOptionEnabled = function(variationId, optionId) {

      if (!variationId || isNaN(variationId)) return;
      if (!optionId || isNaN(optionId)) return;

      variationId = parseInt(variationId, 10);
      optionId = parseInt(optionId, 10);

      var productSelections = $.extend({}, selections);
      delete productSelections[variationId];
      var filteredChildProducts = filterChildProducts(productSelections);
      return optionIsValid(variationId, optionId, filteredChildProducts);
    };

    /*
     * Privileged functions
     */
    this.select = function(variationId, optionId) {

      if (!variationId) return;

      var selections;
      if (typeof variationId === 'object') {
        selections = variationId;
      } else {
        selections = (Object.create ? Object.create(null) : {});
        selections[variationId] = optionId;
      }

      for (var selection in selections) {
        selectVariation(selection, selections[selection]);
      }

      // fire selection changed event
      events(this.events.SELECTION_CHANGED).fireWith(this, [selections]);
    };
    this.clearSelection = function(variation) {

      if (variation) {
        // clear single selection
        selectVariation(variation);
      } else {
        // reset selections
        selections = (Object.create ? Object.create(null) : {});
      }

      // fire selection changed event
      events(this.events.SELECTION_CHANGED).fireWith(this, [selections]);

    };
    this.getSelectedVariationsPostData = function() {
      var variations = [];
      var i = 1;
      for (var variationId in details.variations) {
        variations.push('variation' + i + '=variation' + i + ':' + selections[variationId]);
        i++;
      }
      return variations;
    };
    this.preSelectSingleOptions = function() {
      for (var variationId in details.variations) {
        var count = 0;
        for (var optionId in details.variations[variationId].options) {
          count++;
        }

        if (count === 1) {
          this.select(variationId, optionId);
        }
      }
    };

    this.on = function(event, fn) {

      events(event).add(fn);

    };
    this.off = function(event, fn) {

      events(event).remove(fn);

    };
  }
  ProductDetails.prototype = {
    events: {
      'ERROR': 'error',
      'QUANTITY_CHANGED': 'quantitychanged',
      'SELECTION_CHANGED': 'selectionchanged'
    }
  };

  return ProductDetails;

});
